export default {
  getNextDate (datesArray, referenceDate) {
    var now = new Date(referenceDate)
    var closest = Infinity

    datesArray.forEach(function (d) {
      var date = new Date(d)
      if (date >= now && date < closest) {
        closest = d
      }
    })

    if (closest === Infinity) {
      return null
    } else {
      return closest
    }
  },
  nextDateByDayOfWeek (weekDay, referenceDate) {
    referenceDate = new Date(referenceDate)
    weekDay = weekDay.toLowerCase()
    const days = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ]
    let referenceDateDay = referenceDate.getDay()

    for (var i = 7; i--;) {
      if (weekDay === days[i]) {
        weekDay = (i <= referenceDateDay) ? (i + 7) : i
        break
      }
    }

    let daysUntilNext = weekDay - referenceDateDay

    return referenceDate.setDate(referenceDate.getDate() + daysUntilNext)
  },
  nextDateByDayOfWeekArray (daysArray, referenceDate) {
    let tempArray = []
    for (var i = 0; i < daysArray.length; i++) {
      tempArray.push(new Date(this.nextDateByDayOfWeek(daysArray[i], referenceDate)))
    }
    return this.getNextDate(tempArray, referenceDate)
  },
  isDateLessOrEquals (time1, time2) {
    return new Date(time1) <= new Date(time2)
  },
  countDays (start, end) {
    const oneDay = 24 * 60 * 60 * 1000
    const firstDate = new Date(start)
    const secondDate = new Date(end)

    return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)))
  },
  addDays (date, quantity) {
    let result = new Date(date)
    result.setDate(result.getDate() + quantity)
    return result
  },
  getFirstDay (date, firstDayOfWeek) {
    let firstDay = this.getFirstDayOfMonth(date)
    let offset = 0
    if (firstDayOfWeek > 0) {
      offset = firstDay.getDay() === 0 ? -7 + firstDayOfWeek : firstDayOfWeek
    }
    return new Date(firstDay.setDate(firstDay.getDate() - (firstDay.getDay() - offset)))
  },
  getFirstDayOfMonth (date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
  },
  getNextMonth (date) {
    let nextMonth

    if (date.getMonth() === 11) {
      nextMonth = new Date(date.getFullYear() + 1, 0, 1)
    } else {
      nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    }
    return nextMonth
  },
  getPrevMonth (date) {
    let prevMonth

    if (date.getMonth() === 0) {
      prevMonth = new Date(date.getFullYear() - 1, 11, 1)
    } else {
      prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    }
    return prevMonth
  },

  swipeAfterScroll (direction) {
    if (this.screenSize !== 'desktop' && this.isOpen) {
      const swiperWrapper = document.getElementById('swiperWrapper')
      // When the mobile version is disabled, this element will not exist. eg. (AP currently no mobile version optimization)
      if (!swiperWrapper) return
      // If wrapper has vertical scroll
      if (swiperWrapper.scrollHeight > swiperWrapper.clientHeight) {
        if (swiperWrapper.scrollTop === (swiperWrapper.scrollHeight - swiperWrapper.offsetHeight)) {
          this.renderNextMonth()
        } else if (swiperWrapper.scrollTop === 0) {
          this.renderPreviousMonth()
        } else {

        }
      } else if (direction === 'up') {
        this.renderNextMonth()
      } else if (direction === 'down') {
        this.renderPreviousMonth()
      }
    }
  },

  handleTouchStart (evt) {
    this.xDown = evt.touches[0].clientX
    this.yDown = evt.touches[0].clientY
  },
  getMonthDiff (d1, d2) {
    d1 = new Date(d1)
    d2 = new Date(d2)
    const d1Y = d1.getFullYear()
    const d2Y = d2.getFullYear()
    const d1M = d1.getMonth()
    const d2M = d2.getMonth()
    return (d2M + 12 * d2Y) - (d1M + 12 * d1Y)
  },
  handleTouchMove (evt) {
    if (!this.xDown || !this.yDown) {
      return
    }

    this.xUp = evt.touches[0].clientX
    this.yUp = evt.touches[0].clientY

    let xDiff = this.xDown - this.xUp
    let yDiff = this.yDown - this.yUp

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        // swipe right
      } else {
        // swipe left
      }
    } else {
      if (yDiff > 0) {
        // swipe up
        this.swipeAfterScroll('up')
      } else {
        // swipe down
        this.swipeAfterScroll('down')
      }
    }
    this.xDown = null
    this.yDown = null
  },

  shortenString (arr, sLen) {
    let newArr = []
    for (let i = 0, len = arr.length; i < len; i++) {
      newArr.push(arr[i].substr(0, sLen))
    }
    return newArr
  }
}
