<template>
  <el-popover
    v-model="isOpen"
    :popper-append-to-body="true"
    popper-class="daterange--wrapper"
    trigger="click">
    <!--    prefix-icon="el-icon-date"-->
    <slot
      slot="reference"
      :date="{checkInDate:displayCheckIn,checkOutDate:displayCheckOut}">
      <el-input
        :placeholder="$t('components.DateRange.reference.placeholder')"
        :readonly="true"
        :value="displayStr"
        prefix-icon="el-icon-date">
        <i
          v-if="nights > 0 && !hideNights"
          id="date-range-can"
          slot="suffix"
          class="el-input__icon"
          style="margin-right: 6px;">{{ nights | nightLabel }}</i>
      </el-input>
    </slot>
    <div
      v-if="isOpen"
      :class="`${ isOpen ? 'datepicker--open' : 'datepicker--closed' }`"
      class="datepicker">
      <div
        v-if="displayClearButton"
        class="datepicker__clear-button"
        @click="clearSelection">
        Clear
      </div>
      <div
        v-if="isMobile && isOpen"
        class="datepicker__mobile-header">
        <div
          class="inputFullScreenHeader"
          @click="hideDatepicker">
          <i class="el-icon-arrow-left" /> {{ $t('components.DatePicker.title') }}
        </div>
      </div>
      <div class="datepicker__inner">
        <div class="datepicker__header">
          <span
            :tabindex="isOpen ? 0 : -1"
            class="datepicker__month-button datepicker__month-button--prev -hide-up-to-tablet"
            @click="renderPreviousMonth"
            @keyup.enter.stop.prevent="renderPreviousMonth"><i class="el-icon-arrow-left" /> </span><span
              :tabindex="isOpen ? 0 : -1"
              class="datepicker__month-button datepicker__month-button--next -hide-up-to-tablet"
              @click="renderNextMonth"
              @keyup.enter.stop.prevent="renderNextMonth"><i class="el-icon-arrow-right" /></span>
        </div>
        <div
          v-if="!isMobile"
          class="datepicker__months">
          <div
            v-for="n in [0,1]"
            :key="n"
            class="datepicker__month">
            <p
              class="datepicker__month-name"
              v-text="getMonth(months[activeMonthIndex+n].days[15].date)" />
            <div class="datepicker__week-row -hide-up-to-tablet">
              <div
                v-for="dayName in i18n['day-names']"
                :key="dayName"
                class="datepicker__week-name"
                v-text="dayName" />
            </div>
            <div
              v-for="(day,i) in months[activeMonthIndex+n].days"
              :key="i"
              class="square"
              @mouseover="hoveringDate = day.date">
              <Day
                :is-open="isOpen"
                :options="$props"
                :date="day.date"
                :sorted-disabled-dates="sortedDisabledDates"
                :next-disabled-date="nextDisabledDate"
                :active-month-index="activeMonthIndex"
                :hovering-date="hoveringDate"
                :tooltip-message="tooltipMessage"
                :day-number="getDay(day.date)"
                :belongs-to-this-month="day.belongsToThisMonth"
                :check-in="checkIn"
                :check-out="checkOut"
                :current-date-style="currentDateStyle"
                :no-min-date="noMinDate"
                @day-clicked="handleDayClick($event)" />
            </div>
          </div>
        </div>
        <div v-if="isMobile && isOpen">
          <div class="datepicker__week-row">
            <div
              v-for="dayName in i18n['day-names']"
              :key="dayName"
              class="datepicker__week-name"
              v-text="dayName" />
          </div>
          <div
            id="swiperWrapper"
            class="datepicker__months">
            <div
              v-for="(a, n) in months"
              :key="n"
              class="datepicker__month">
              <p
                class="datepicker__month-name"
                v-text="getMonth(months[n].days[15].date)" />
              <div class="datepicker__week-row -hide-up-to-tablet">
                <div
                  v-for="dayName in i18n['day-names']"
                  :key="dayName"
                  class="datepicker__week-name"
                  v-text="dayName" />
              </div>
              <div
                v-for="(day, index) in months[n].days"
                :key="index"
                class="square"
                @mouseover="hoveringDate = day.date"
                @focus="hoveringDate = day.date">
                <Day
                  :is-open="isOpen"
                  :options="$props"
                  :date="day.date"
                  :sorted-disabled-dates="sortedDisabledDates"
                  :next-disabled-date="nextDisabledDate"
                  :active-month-index="activeMonthIndex"
                  :hovering-date="hoveringDate"
                  :tooltip-message="tooltipMessage"
                  :day-number="getDay(day.date)"
                  :belongs-to-this-month="day.belongsToThisMonth"
                  :check-in="checkIn"
                  :check-out="checkOut"
                  :current-date-style="currentDateStyle"
                  :no-min-date="noMinDate"
                  @day-clicked="handleDayClick($event)" />
              </div>
            </div>
            <div
              class="next--mobile"
              type="button"
              @click="renderNextMonth" />
          </div>
          <div class="mobile-done-wrapper">
            <el-button
              :disabled="!checkIn || !checkOut"
              class="btn-done"
              @click="hideDatepicker">
              {{ $t('app.done') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
import throttle from 'lodash/throttle'
import moment from 'moment'

import Day from './Day.vue'
import Helpers from './helpers.js'

export default {
  name: 'DatePicker',

  components: {
    Day
  },

  props: {
    currentDateStyle: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object
    },
    format: {
      default: 'YYYY-MM-DD',
      type: String
    },
    startDate: {
      default: function () {
        return new Date()
      },
      type: [
        Date,
        String
      ]
    },
    endDate: {
      default: function () {
        return new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      },
      type: [
        Date,
        String
      ]
    },
    firstDayOfWeek: {
      default: 0,
      type: Number
    },
    minNights: {
      default: 1,
      type: Number
    },
    maxNights: {
      default: null,
      type: Number
    },
    disabledDates: {
      default: function () {
        return []
      },
      type: Array
    },
    disabledDaysOfWeek: {
      default: function () {
        return []
      },
      type: Array
    },
    allowedRanges: {
      default: function () {
        return []
      },
      type: Array
    },
    hoveringTooltip: {
      default: true,
      type: [
        Boolean,
        Function
      ]
    },
    tooltipMessage: {
      default: null,
      type: String
    },
    enableCheckout: {
      default: true,
      type: Boolean
    },
    singleDaySelection: {
      default: false,
      type: Boolean
    },
    showYear: {
      default: true,
      type: Boolean
    },
    closeDatepickerOnClickOutside: {
      default: true,
      type: Boolean
    },
    displayClearButton: {
      default: false,
      type: Boolean
    },
    noMinDate: {
      default: false,
      type: Boolean
    },
    hideNights: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      hoveringDate: null,
      checkIn: this.sanitizeDate(this.value.checkInDate),
      checkOut: this.sanitizeDate(this.value.checkOutDate),
      months: [],
      activeMonthIndex: 0,
      nextDisabledDate: null,
      show: true,
      isOpen: false,
      xDown: null,
      yDown: null,
      xUp: null,
      yUp: null,
      sortedDisabledDates: null,
      i18n: {
        night: this.$t('filters.night'),
        nights: this.$t('filters.nights'),
        'day-names': [
          this.$t('datepicker.daysShort.sunday'),
          this.$t('datepicker.daysShort.monday'),
          this.$t('datepicker.daysShort.tuesday'),
          this.$t('datepicker.daysShort.wednesday'),
          this.$t('datepicker.daysShort.thursday'),
          this.$t('datepicker.daysShort.friday'),
          this.$t('datepicker.daysShort.saturday')
        ],
        'month-names': [
          this.$t('datepicker.monthNames.january'),
          this.$t('datepicker.monthNames.february'),
          this.$t('datepicker.monthNames.march'),
          this.$t('datepicker.monthNames.april'),
          this.$t('datepicker.monthNames.may'),
          this.$t('datepicker.monthNames.june'),
          this.$t('datepicker.monthNames.july'),
          this.$t('datepicker.monthNames.august'),
          this.$t('datepicker.monthNames.september'),
          this.$t('datepicker.monthNames.october'),
          this.$t('datepicker.monthNames.november'),
          this.$t('datepicker.monthNames.december')
        ]
      }
    }
  },

  computed: {
    showClearSelectionButton () {
      return Boolean((this.checkIn || this.checkOut) && this.displayClearButton)
    },
    isMobile () {
      return this.$mq.mobile
    },
    displayStr () {
      if (!this.checkIn) {
        return ''
      }
      const dateFormat = 'MMM DD'
      const locale = this.$i18n ? this.$i18n.locale : ''
      const checkIn = this.checkIn ? moment(this.checkIn).locale(locale).format(dateFormat) : ''
      const checkOut = this.checkOut ? moment(this.checkOut).locale(locale).format(dateFormat) : ''

      return `${checkIn}  –  ${checkOut}`
    },
    displayCheckIn () {
      if (!this.checkIn) {
        return ''
      }
      const dateFormat = 'MMM DD YYYY'
      const locale = this.$i18n ? this.$i18n.locale : ''
      const checkIn = this.checkIn ? moment(this.checkIn).locale(locale).format(dateFormat) : ''
      return checkIn
    },
    displayCheckOut () {
      if (!this.checkOut) {
        return ''
      }
      const dateFormat = 'MMM DD YYYY'
      const locale = this.$i18n ? this.$i18n.locale : ''
      const checkOut = this.checkOut ? moment(this.checkOut).locale(locale).format(dateFormat) : ''
      return checkOut
    },
    nights () {
      return this.$root.$options.filters.countDays(this.checkIn, { endDate: this.checkOut })
    }
  },

  watch: {
    value (nV) {
      this.checkIn = this.sanitizeDate(this.value.checkInDate)
      this.checkOut = this.sanitizeDate(this.value.checkOutDate)
    },
    isOpen (value) {
      if (this.isMobile) {
        const bodyClassList = document.querySelector('body').classList

        if (value) {
          bodyClassList.add('-overflow-hidden')
          setTimeout(() => {
            const swiperWrapper = document.getElementById('swiperWrapper')
            const monthWrapper = document.querySelector('.datepicker__month')
            if (!monthWrapper) return false
            const monthHeight = monthWrapper.offsetHeight
            let index = 0
            if (this.checkIn) {
              index = this.getMonthDiff(this.startDate, this.checkIn)
            }
            swiperWrapper.scrollTop = index ? monthHeight * index : 0
          }, 100)
        } else {
          bodyClassList.remove('-overflow-hidden')
        }
      }

      if (!value) {
        if (this.checkIn && !this.checkOut) {
          this.checkIn = null
        }

        // Don't emit event of both dates are not present
        if (!this.checkIn && !this.checkOut) {
          return
        }

        this.$emit('input', {
          checkInDate: this.formatDate(this.checkIn),
          checkOutDate: this.formatDate(this.checkOut)
        })
      }
    },
    checkIn (newDate) {
      this.$emit('check-in-changed', newDate)
    },
    checkOut (newDate) {
      if (this.checkIn !== null && this.checkOut !== null) {
        this.hoveringDate = null
        this.nextDisabledDate = null
        this.show = true
        this.parseDisabledDates()
        this.reRender()
        if (!this.isMobile) {
          this.hideDatepicker()
        }
      }

      this.$emit('check-out-changed', newDate)
    }
  },

  mounted () {
    document.addEventListener('touchstart', this.handleTouchStart, false)
    document.addEventListener('touchmove', this.handleTouchMove, false)

    this.onElementHeightChange(document.body, () => {
      this.emitHeighChangeEvent()
    })
  },

  destroyed () {
    window.removeEventListener('touchstart', this.handleTouchStart)
    window.removeEventListener('touchmove', this.handleTouchMove)
  },

  beforeMount () {
    if (this.endDate !== Infinity) {
      this.createMonth(new Date(this.startDate))
      const count = this.getMonthDiff(this.startDate, this.endDate)
      let nextMonth = new Date(this.startDate)
      for (let i = 0; i < count; i++) {
        let tempNextMonth = this.getNextMonth(nextMonth)
        this.createMonth(tempNextMonth)
        nextMonth = tempNextMonth
      }
      if (this.checkIn && this.checkOut) {
        this.activeMonthIndex = this.getMonthDiff(this.startDate, this.checkOut)
        if (this.getMonthDiff(this.checkIn, this.checkOut) <= 0 && this.activeMonthIndex > 0) {
          this.activeMonthIndex -= 1
        }
        // fix bug AP-768,This problem occurs when today and chekOut date differ by more than one year
        if (this.activeMonthIndex >= this.months.length - 1) {
          this.activeMonthIndex = this.months.length - 2
        }
      }
    } else if (this.checkIn && (this.getMonthDiff(this.getNextMonth(new Date(this.startDate)), this.checkIn) > 0 || this.getMonthDiff(this.startDate, this.checkIn) > 0)) {
      this.createMonth(new Date(this.startDate))
      const count = this.getMonthDiff(this.startDate, this.checkIn)
      let nextMonth = new Date(this.startDate)
      for (let i = 0; i <= count; i++) {
        let tempNextMonth = this.getNextMonth(nextMonth)
        this.createMonth(tempNextMonth)
        nextMonth = tempNextMonth
      }
      if (this.checkOut && this.getMonthDiff(this.checkIn, this.checkOut) > 0) {
        this.createMonth(this.getNextMonth(nextMonth))
        this.activeMonthIndex = 1
      }
      this.activeMonthIndex += count
    } else {
      this.createMonth(new Date(this.startDate))
      this.createMonth(this.getNextMonth(new Date(this.startDate)))
    }
    this.parseDisabledDates()
  },

  methods: {
    ...Helpers,
    sanitizeDate (date) {
      if (!date) return null

      let compareDate = (date1, date2) => {
        return moment(date1).format('YYYYMMDD') <= moment(date2).format('YYYYMMDD')
      }

      if (this.noMinDate) {
        return compareDate(date, this.endDate) ? moment(date).toDate() : null
      } else {
        return compareDate(date, this.endDate) && compareDate(this.startDate, date) ? moment(date).toDate() : null
      }
    },

    formatDate (date) {
      if (date) {
        return moment(date).format(this.format)
      }
      return ''
    },

    onElementHeightChange (el, callback) {
      let lastHeight = el.clientHeight
      let newHeight = lastHeight;

      (function run () {
        newHeight = el.clientHeight

        if (lastHeight !== newHeight) {
          callback()
        }

        lastHeight = newHeight

        if (el.onElementHeightChangeTimer) {
          clearTimeout(el.onElementHeightChangeTimer)
        }

        el.onElementHeightChangeTimer = setTimeout(run, 1000)
      })()
    },

    emitHeighChangeEvent () {
      this.$emit('height-changed')
    },

    reRender () {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    clearSelection () {
      this.hoveringDate = null
      this.checkIn = null
      this.checkOut = null
      this.nextDisabledDate = null
      this.show = true
      this.parseDisabledDates()
      this.reRender()
      this.hideDatepicker()
      this.$emit('input', {
        checkInDate: null,
        checkOutDate: null
      })
    },

    hideDatepicker () {
      this.isOpen = false
    },

    showDatepicker () {
      this.isOpen = true
    },

    toggleDatepicker () {
      this.isOpen = !this.isOpen
    },

    clickOutside () {
      if (this.closeDatepickerOnClickOutside) {
        this.hideDatepicker()
      }
    },

    handleDayClick (event) {
      if (this.checkIn == null && this.singleDaySelection === false) {
        this.checkIn = event.date
      } else if (this.singleDaySelection === true) {
        this.checkIn = event.date
        this.checkOut = event.date
      } else if (this.checkIn !== null && this.checkOut == null && (this.minNights === 0 || moment(this.checkIn).format('YYYYMMDD') !== moment(event.date).format('YYYYMMDD'))) {
        this.checkOut = event.date
      } else {
        this.checkOut = null
        this.checkIn = event.date
        this.hoveringDate = null
      }

      // switch checkIn checkOut when chenckIn> checkOut
      if (this.checkIn && this.checkOut && (moment(this.checkIn).format('YYYYMMDD') > moment(this.checkOut).format('YYYYMMDD'))) {
        let _checkIn = this.checkIn
        let _checkOut = this.checkOut
        this.checkIn = _checkOut
        this.checkOut = _checkIn
      }

      this.nextDisabledDate = event.nextDisabledDate
    },

    renderPreviousMonth () {
      if (this.activeMonthIndex >= 1) {
        this.activeMonthIndex--
      } else {
        if (this.noMinDate) {
          let firstDayOfFirstMonth = this.months[0].days.filter((day) => day.belongsToThisMonth === true)
          this.createMonth(this.getPrevMonth(firstDayOfFirstMonth[0].date), 'unshift')
        } else {

        }
      }
    },

    renderNextMonth: throttle(function throttleRenderNextMonth () {
      if (this.activeMonthIndex < this.months.length - 2) {
        this.activeMonthIndex++
        return
      }

      let firstDayOfLastMonth

      if (this.isMobile) {
        firstDayOfLastMonth = this.months[this.months.length - 1].days.filter((day) => day.belongsToThisMonth === true)
      } else {
        firstDayOfLastMonth = this.months[this.activeMonthIndex + 1].days.filter((day) => day.belongsToThisMonth === true)
      }

      if (this.endDate !== Infinity) {
        if (moment(firstDayOfLastMonth[0].date).format('YYYYMM') === moment(new Date(this.endDate)).format('YYYYMM')) {
          return
        }
      }

      this.createMonth(this.getNextMonth(firstDayOfLastMonth[0].date))

      this.activeMonthIndex++
    }, 200),

    setCheckIn (date) {
      this.checkIn = date
    },

    setCheckOut (date) {
      this.checkOut = date
    },

    getDay (date) {
      return moment(date).format('D')
    },

    getMonth (date) {
      return this.i18n['month-names'][moment(date).format('M') - 1] + (this.showYear ? moment(date).format(' YYYY') : '')
    },

    createMonth (date, method = 'push') {
      const firstDay = this.getFirstDay(date, this.firstDayOfWeek)
      let month = {
        days: []
      }

      for (let i = 0; i < 42; i++) {
        month.days.push({
          date: this.addDays(firstDay, i),
          belongsToThisMonth: this.addDays(firstDay, i).getMonth() === date.getMonth(),
          isInRange: false
        })
      }
      this.months[method](month)
    },

    parseDisabledDates () {
      const sortedDates = []

      for (let i = 0; i < this.disabledDates.length; i++) {
        sortedDates[i] = new Date(this.disabledDates[i])
      }

      sortedDates.sort((a, b) => a - b)

      this.sortedDisabledDates = sortedDates
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/bulma-variables';

  /* =============================================================
   * VARIABLES
   * ============================================================*/

  $gray: $grey;
  $primary-text-color: $grey-dark;
  $lightest-gray: $white;
  $primary-color: $primary;
  $medium-gray: $grey-dark;
  $light-gray: $grey-light;
  $dark-gray: $grey-darker;

  $font-small: 14px;

  /* =============================================================
   * RESPONSIVE LAYOUT HELPERS
   * ============================================================*/

  $desktop: '(min-width: 769px)';
  $up-to-tablet: '(max-width: 768px)';

  @mixin focusStyle() {
    &:focus {
      outline: 1px dashed darken($primary-color, 10%);
      outline-offset: -10px;
    }
  }

  @mixin device($device-widths) {
    @media screen and #{$device-widths} {
      @content
    }
  }

  .square {
    width: calc(100% / 7);
    float: left;
    @include device($desktop) {
      cursor: pointer;
    }
  }

  .daterange--wrapper {
    padding: 0 !important;

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
  }

  /* =============================================================
   * BASE STYLES
   * ============================================================*/

  .datepicker {
    //transition: all .2s ease-in-out;
    background-color: $white;
    color: $gray;
    font-size: 16px;
    line-height: 14px;
    overflow: hidden;
    /*left: 0;*/
    /*top: 48px;*/
    /*position: absolute;*/
    /*z-index: 999;*/

    button.next--mobile {
      background: none;
      border: 1px solid $light-gray;
      float: none;
      height: 50px;
      width: 100%;
      position: relative;
      background-position: center;
      appearance: none;
      overflow: hidden;
      position: fixed;
      bottom: 0;
      left: 0;
      outline: none;
      box-shadow: 0 5px 30px 10px rgba($black, .08);
      background: white;

      &:after {
        background: #0a0a0a;
        transform: rotate(90deg);
        content: "";
        position: absolute;
        width: 200%;
        height: 200%;
        top: -50%;
        left: -50%;
      }
    }

    &--closed {
      box-shadow: 0 15px 30px 10px rgba($black, 0);
      max-height: 0;
    }

    &--open {
      box-shadow: 0 15px 30px 10px rgba($black, .08);
      max-height: 900px;

      @include device($up-to-tablet) {
        box-shadow: none;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-overflow-scrolling: touch !important;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99999;
      }
    }

    &__input {
      background: transparent;
      height: 48px;
      color: $primary-text-color;
      font-size: 12px;
      outline: none;
      padding: 4px 30px 2px;
      width: 100%;
      word-spacing: 5px;
      border: 0;

      @include focusStyle();

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: $primary-text-color;
      }
    }

    &__dummy-wrapper {
      border: solid 1px $light-gray;
      cursor: pointer;
      display: block;
      float: left;
      width: 100%;
      height: 100%;

      &--no-border.datepicker__dummy-wrapper {
        margin-top: 15px;
        border: 0;
      }

      &--is-active {
        border: 1px solid $primary-color;
      }
    }

    &__month-day {
      visibility: visible;
      text-align: center;
      margin: 0;
      border: 0;
      height: 40px;
      padding-top: 14px;

      @include focusStyle();

      &--invalid-range {
        background-color: rgba($primary-color, .3);
        color: $lightest-gray;
        cursor: not-allowed;
        position: relative;
      }

      &--invalid {
        color: $lightest-gray;
        cursor: not-allowed;
      }

      &--valid:hover,
      &--allowed-checkout:hover {
        background-color: $white;
        color: $primary-color;
        z-index: 1;
        position: relative;
        box-shadow: 0 0 10px 3px rgba($gray, .4);
      }

      &--disabled {
        opacity: 0.25;
        cursor: not-allowed;
        pointer-events: none;
        position: relative;
      }

      &--selected {
        background-color: rgba($primary-color, .3);
        color: $white;
        opacity: 1 !important;

        &:hover {
          background-color: $white;
          color: $primary-color;
          z-index: 1;
          position: relative;
          box-shadow: 0 0 10px 3px rgba($gray, .4);
        }
      }

      &--first-day-selected,
      &--last-day-selected {
        background: $primary-color;
        color: $white;
        opacity: 1 !important;
      }

      &--allowed-checkout {
        color: $medium-gray;
      }

      &--out-of-range {
        color: $lightest-gray;
        cursor: not-allowed;
        position: relative;
        pointer-events: none;
      }

      &--valid {
        cursor: pointer;
        color: $medium-gray;
      }

      &--hidden {
        opacity: 0.25;
        pointer-events: none;
        color: $white;
      }
    }

    &__today {
      border: 1px solid $primary-color;
    }

    &__month-button {
      background: transparent;
      cursor: pointer;
      display: inline-block;
      height: 60px;
      width: 60px;
      text-align: center;
      line-height: 60px;

      @include focusStyle();

      &--next {
        float: right;
      }

      &--locked {
        opacity: .2;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &__inner {
      padding: 20px;
      float: left;

      @include device($up-to-tablet) {
        padding: 0;
      }
    }

    &__months {
      @include device($desktop) {
        width: 650px;
      }

      @include device($up-to-tablet) {
        margin-top: 92px;
        height: calc(100% - 182px);
        position: absolute;
        left: 0;
        top: 0;
        overflow: scroll;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      &::before {
        background: $light-gray;
        bottom: 0;
        content: '';
        display: block;
        left: 50%;
        position: absolute;
        top: 0;
        width: 1px;

        @include device($up-to-tablet) {
          display: none;
        }
      }
    }

    &__month {
      font-size: 12px;
      float: left;
      width: 50%;
      padding-right: 10px;

      @include device($up-to-tablet) {
        width: 100%;
        padding-right: 0;
        padding-top: 60px;

        &:last-of-type {
          margin-bottom: 65px;
        }
      }

      @include device($desktop) {
        &:last-of-type {
          padding-right: 0;
          padding-left: 10px;
        }
      }
    }

    &__month-caption {
      height: 2.5em;
      vertical-align: middle;
    }

    &__month-name {
      font-size: 16px;
      margin-top: -40px;
      padding-bottom: 17px;
      pointer-events: none;
      text-align: center;
      font-weight: bold;

      @include device($up-to-tablet) {
        margin-top: -46px;
        margin-bottom: 0;
        position: absolute;
        padding-top: $bleed*2;
        padding-bottom: $bleed*2;
        width: 100%;
        background-color: #F0F0F0;
      }
    }

    &__week-days {
      height: 2em;
      vertical-align: middle;
    }

    &__week-row {
      border-bottom: 5px solid $white;
      height: 38px;

      @include device($up-to-tablet) {
        height: 25px;
        left: 0;
        top: 65px;
        position: absolute;
        width: 100%;
        border-bottom-color: transparent;
      }
    }

    &__week-name {
      width: calc(100% / 7);
      float: left;
      font-size: 12px;
      font-weight: 400;
      color: $medium-gray;
      text-align: center;
      @include device($up-to-tablet) {
        color: $white;
      }
    }

    &__close-button {
      appearance: none;
      background: transparent;
      border: 0;
      color: $primary-color;
      cursor: pointer;
      font-size: 21px;
      font-weight: bold;
      margin-top: 0;
      outline: 0;
      z-index: 10000;
      position: fixed;
      left: 7px;
      top: 5px;
      transform: rotate(45deg);
    }

    &__clear-button {
      appearance: none;
      background: transparent;
      border: 0;
      cursor: pointer;
      // font-size: 25px;
      font-weight: bold;
      // height: 40px;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 6px;
      margin-top: 6px;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 2.5em;
      white-space: nowrap;

      svg {
        fill: none;
        stroke-linecap: round;
        stroke-width: 8px;
        stroke: $medium-gray;
        width: 20px;
        width: 14px;
        top: -3px;
        position: relative;
      }

      @include focusStyle();
    }

    &__tooltip {
      background-color: $dark-gray;
      border-radius: 2px;
      color: $white;
      font-size: 11px;
      margin-left: 5px;
      margin-top: -22px;
      padding: 5px 10px;
      position: absolute;
      z-index: 50;

      &:after {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $dark-gray;
        bottom: -4px;
        content: '';
        left: 50%;
        margin-left: -4px;
        position: absolute;
      }
    }
  }

  // Modifiers

  .-overflow-hidden {
    overflow: hidden;
  }

  .-is-hidden {
    display: none;
  }

  .-hide-up-to-tablet {
    @include device($up-to-tablet) {
      display: none;
    }
  }

  .-hide-on-desktop {
    @include device($desktop) {
      display: none;
    }
  }

  .datepicker__mobile-header {
    height: 90px;
    background-color: $primary;
    padding-left: 25px;
    padding-right: 25px;
  }
</style>
